import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import { Col, Row } from 'react-bootstrap';
import { CategoryCard } from '../../../modules/cs/main/components/category-card';
import { BCMCharacter } from '../../../modules/bcm/common/components/bcm-character';
import { YoutubeEmbed } from '../../../modules/common/components/youtube-embed';

const BCMGuidesBeginnerPage: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page'} game="bcm">
      <ul className="breadcrumb">
        <li>
          <Link to="/black-clover/">Black Clover M</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/black-clover/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Beginner guide</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/blackclover/categories/cat_beginner.png"
            alt="Beginner guide"
          />
        </div>
        <div className="page-details">
          <h1>Beginner guide</h1>
          <h2>The best beginner guide for Black Clover Mobile.</h2>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Introduction" />
        <p>
          The guide was created by <strong>Hollow#2494</strong>. Huge thanks to
          him!
        </p>
        <p>
          Welcome everyone to the beginners guide! Before I indulge into
          details, I'd like to make one thing a super important priority, and
          this thing is to{' '}
          <strong>burn your Story Tickets asap when you start out</strong>.
        </p>
        <p>
          In this game, the story is locked behind Tickets which refresh each 3
          hours and you don't want for them to overflow -{' '}
          <strong>
            those tickets are also used for boss dungeons and they give you
            around 200 gems daily
          </strong>
          , which you don't want to miss out on and want to start farming right
          after story. If you manage your{' '}
          <strong>
            Story Tickets right, you should be able to clear Chapter 3 in 4-5
            days
          </strong>
          . Chapter 4 was added 2 weeks after the game has released in KR/JPN
          and we're not sure if it will be available right away on the Global
          release.
        </p>
        <p>
          <strong>Important!</strong> It seems the Story Tickets have been
          removed in Global and you just need stamina to tackle the story
          stages. This means you can progress faster and obtain way more
          rewards.
        </p>
        <p>
          Now for the actual start of the guide, this guide will basically focus
          on the path from start till the point you start{' '}
          <strong>
            consistently farming for LR rarity gear at Stage 15 on gear dungeons
          </strong>
          . First thing you'd want to do is prioritize just 4 units to work on,
          preferably <strong className="teal">Blue type units</strong>, since
          they will be the ones you'd use for the first gear dungeon. So
          regarding units, there's several things to keep in mind: affinity,
          exp, rarity and gear - are the most important ones, there's also
          talents and stars but those i'll explain later.
        </p>
        <SectionHeader title="Character - Affinity" />
        <StaticImage
          src="../../../images/blackclover/beginner/7.jpg"
          alt="Beginner guide"
        />
        <p>
          Affinity is basically like relationship, you can and will need to farm
          it in order to raise the affinity level for units quickly. As for why,
          its because in <strong>this game, dupes aren't mandatory</strong> -
          you get character and universal shards from building up affinity for
          the character and you use them to limit break them so to say.
        </p>
        <p>
          Let's take SSR rarity unit as example - they starts at SSR, then go to
          UR and then to LR, but you only need <strong>universal shards</strong>{' '}
          for the UR break, and both for UR and LR you'll need{' '}
          <strong>5 character specific shards</strong> (2 for UR and 3 for LR).
          In any other game you would need to get 5 dupes to get those shards,
          but in Black Clover Mobile, you can unlock them via the Affinity
          system.
        </p>
        <StaticImage
          src="../../../images/blackclover/beginner/2.jpg"
          alt="Beginner guide"
        />
        <p>
          As for how to actually farm for the affinity consistently, the best
          way is to do stages on those <strong>'huts' on the maps</strong> - any
          stage from normal till nightmare will give more affinity than anything
          else in the game currently.
        </p>
        <p>
          Oh and each unit has a <strong>costume on lvl 9 of affinity</strong>,
          some of them are quite goofy, some are pretty sick, regardless, it's a
          pretty nice addition.
        </p>
        <StaticImage
          src="../../../images/blackclover/beginner/4.jpg"
          alt="Beginner guide"
        />
        <p>
          And no, costumes in this game don't you any stats - they just alter
          the look of the character.
        </p>
        <SectionHeader title="Character - Experience" />
        <StaticImage
          src="../../../images/blackclover/beginner/3.jpg"
          alt="Beginner guide"
        />
        <p>
          Next on the list is exp - pretty self explanatory, just experience
          you'll use to level characters. This is also farmable by doing
          specific exp stages in the farming section part of the games menu,
          levels go from 1-60 at SSR, then 60-80 at UR and 80-100 for LR.
        </p>
        <p>
          For lower rarity units the cap respectively changes from lvl 1 – 40
          for SR and 1 – 20 for R, lower rarity units are also able to be broken
          up to LR, just the whole process takes a bit longer.
        </p>
        <SectionHeader title="Character - Rarity" />
        <StaticImage
          src="../../../images/blackclover/beginner/8.jpg"
          alt="Beginner guide"
        />
        <p>
          Now the rarity, as I mentioned in the last section, there's several
          rarities in this game: <strong>R – SR – SSR – UR – LR</strong>, and
          what's important is that all the units can be brought up to LR rarity.
        </p>
        <p>
          The biggest difference is the change in their base stats. SSR units
          have them higher than all the other rarities, but that doesn't mean
          that SR units are by any means bad though - there are several you can
          use just fine or as a substitute if you're missing someone from the
          SSR rarity as well. As for the breakthrough mechanic itself, as I
          mentioned above, you'll need shards which you can farm by raising
          their affinity.
        </p>
        <SectionHeader title="Character - Talents and Star levels" />
        <StaticImage
          src="../../../images/blackclover/beginner/5.jpg"
          alt="Beginner guide"
        />
        <p>
          Now a little something regarding talents and stars.{' '}
          <strong>
            Talents are additional passives you can unlock for each character
          </strong>{' '}
          - you farm a specific dungeon, then use those materials to unlock 2
          talents from each tree on a unit, and that unit gains extra things to
          say in a simple manner.
        </p>
        <StaticImage
          src="../../../images/blackclover/beginner/6.jpg"
          alt="Beginner guide"
        />
        <p>
          Stars are obtained by using a character shard on the unit that's
          already LR, they give stat boosts and at Star level 2, you unlock
          another passive, but they're not really game changers, and the stat
          boosts aren't high, so this is just something to keep in mind if you
          get an extra dupe of them.
        </p>
        <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
        <SectionHeader title="Gear" />
        <StaticImage
          src="../../../images/blackclover/beginner/9.jpg"
          alt="Beginner guide"
        />
        <p>
          Gear is probably one of the more important things, like in most gachas
          honestly. So with gear, you have the same type of rarities from R-LR,
          and gear is also farmable in specific dungeons.
        </p>
        <p>
          There are 3 dungeons available: <strong>Red, Blue and Green</strong> –
          and for each of these dungeons the{' '}
          <strong>recommended teams to use are mono teams</strong>. Mono means a
          single color team of counter element in this case, against Red
          dungeon, you'll want to run a Blue team as example.
        </p>
        <p>It's important to know that there are also two types of gear: </p>
        <ul>
          <li>Base gear</li>
          <li>Golden gear</li>
        </ul>
        <p>
          Golden gear has a gold border around the icon and it starts dropping
          from the Stage 10 in each of the gear dungeons - that's the gear you
          want to focus on.{' '}
          <strong>
            Treat the base gear as placeholders (as in use the pieces for the
            main stats boost, but don't force yourself to finish a set).
          </strong>{' '}
          For example, a UR base gear piece with MATK main stat will give you a
          bigger boost than 2x SSR set pieces.
        </p>
        <p>So the priority for equipping gear is as follows:</p>
        <ul>
          <li>
            Higher rarity - it's always better due to the massive boost to the
            main stat,
          </li>
          <li>
            Correct main stats - MATK/ATK on attackers/supporters/debuffers, DEF
            or HP on defenders (but there are some exceptions to that),
          </li>
          <li>Correct gold border set with correct main stat,</li>
          <ul>
            <li>
              Also equipping 3x 2-piece sets is better than going for 1x 4-piece
              set and 1x 2-piece set. Simply because to activate a 4-piece set
              you're forced to equip gear pieces with HP/DEF main stats and this
              is bad especially for offensive characters.
            </li>
          </ul>
        </ul>
        <StaticImage
          src="../../../images/blackclover/beginner/11.jpg"
          alt="Beginner guide"
        />
        <p>
          Each of these dungeons have different sets of Golden gear they drop:
        </p>
        <ul>
          <li>
            <strong className="red">Red dungeon</strong> - ATK, Speed and DEF
            sets
          </li>
          <li>
            <strong className="teal">Blue dungeon</strong> - CRIT Rate and HP
            sets
          </li>
          <li>
            <strong className="green">Green dungeon</strong> - CRIT DMG and
            Penetration sets
          </li>
        </ul>
        <p>
          To learn more about the available gear sets and which teams to use to
          farm the Gear dungeons, check our other guides:
        </p>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Gear Sets"
            link="/black-clover/guides/gear-sets"
            image={
              <StaticImage
                src="../../../images/blackclover/categories/cat_sets.png"
                alt="Best gear sets"
              />
            }
          />
          <CategoryCard
            title="Best teams to farm gear"
            link="/black-clover/guides/best-teams-to-farm-gear"
            image={
              <StaticImage
                src="../../../images/blackclover/categories/cat_gear.png"
                alt="Best teams for farm gear"
              />
            }
          />
        </Row>
        <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
        <SectionHeader title="How to spend stamina?" />
        <p>This part of the guide will be added soon!</p>
        <SectionHeader title="Who to pull?" />
        <p>
          That's pretty much the most important things regarding units, now
          about who to actually pull for right?
        </p>
        <p>
          There might be several different suggestions since the best start is
          quite subjective and also time investment dependent, but in my
          opinion, if you want the best start aka godroll, you'd want to start
          with{' '}
          <strong>
            Blue/Red Yami, Mars and either Lotus or William (or both if you get
            lucky enough.)
          </strong>
        </p>
        <div className="employee-container for-nikke">
          <BCMCharacter
            mode="icon"
            slug="yami-sukehiro-clover-academy"
            enablePopover
          />
          <BCMCharacter mode="icon" slug="yami-sukehiro" enablePopover />
          <BCMCharacter mode="icon" slug="mars" enablePopover />
          <BCMCharacter mode="icon" slug="lotus-whomalt" enablePopover />
          <BCMCharacter mode="icon" slug="william-vangeance" enablePopover />
        </div>
        <p>
          These 3-4 units are the best ones to start with, since they're all
          super strong in most of the content the game currently has. Regarding
          which Yami to go for, its kinda up to you, I personally prefer Red
          Yami since he just puts more raw damage to the table than Blue does,
          but Blue is very good in PvP and still does decent damage in PvE
          content.
        </p>
        <p>
          The main reason though, why I'm suggesting these units, is due to them
          all being superb units for the first, aka Red Dungeon, you'll want to
          farm.
        </p>
        <ul>
          <li>
            <strong>Mars</strong> is the best tank there, hands down, there's an
            SR defender called Sol that can substitute him, but Mars just makes
            the runs easier and safer in a shorter amount of time since you
            started playing on that account,
          </li>
          <li>
            <strong>Lotus</strong> is amazing since he slows down the mobs and
            puts a debuff on them which increases their damage taken,
          </li>
          <li>
            <strong>William</strong> is also great, since his Skill 2 cooldown
            is 2 turns, so there's only 1 turn when he uses his basic attack and
            then he can boost the main dps again, giving them a pretty
            significant damage increase for 2 turns on top of boosting their
            stamina.
          </li>
        </ul>
        <SectionHeader title="Gateway of Destiny" />
        <StaticImage
          src="../../../images/blackclover/generic/gateway.jpg"
          alt="Black Clover Mobile"
        />
        <p>
          Gateway of Destiny is a very interesting system that allows you to
          obtain one of the special seasonal characters for free. The first
          season features 3 characters: Asta, Yami and Mimosa.
        </p>
        <div className="employee-container for-nikke">
          <BCMCharacter mode="icon" slug="asta-clover-academy" enablePopover />
          <BCMCharacter
            mode="icon"
            slug="mimosa-vermillion-clover-academy"
            enablePopover
          />
          <BCMCharacter
            mode="icon"
            slug="yami-sukehiro-clover-academy"
            enablePopover
          />
        </div>
        <p>
          The seasonal characters are actually really strong - they sit among
          the best characters in the game. Asta ia great Red Attacker, Mimosa is
          the best Healer in the game and Yami is really annoying in PVP (but
          with Licht release the Blue meta shifted a bit in KR/JPN), and still
          decent in PVE.
        </p>
        <p>
          When the season first starts, there will be a dedicated Seasonal
          banner where you will have a higher chance to get the seasonal
          characters, but after 2 weeks, once the banner ends, they will be
          added to the General Pool of character. Still, once a new season
          releases, they will be removed again and replaced by the new Seasonal
          characters.
        </p>
        <SectionHeader title="Summary" />
        <ul>
          <li>Focus only on the main team at the start,</li>
          <li>
            Don’t use character shards for anything else but the unit
            breakthroughs,
          </li>
          <li>
            Use your Story Tickets asap, try to burn through all of them on day
            1,
          </li>
          <li>
            Gear dungeon stage 10 is your first big priority to get to as then
            you can star farming Gold Gear,
          </li>
          <ul>
            <li>
              Once you gear your team in UR rarity pieces, you can start pushing
              forward and farming LR gear in Stages 12-15.
            </li>
          </ul>
          <li>
            Dupes aren’t mandatory, as an f2p, you’ll only want the unit and
            maybe their Signature Grimoire,
          </li>
          <li>
            Game is grindy, take your own pace and don’t rush, it should be
            played as a marathon, not a sprint, most importantly, enjoy it,
          </li>
          <li>
            Try to give PvP some attention as well, it gives a solid amount of
            gems, and isn’t too hard to climb with a decent team.
          </li>
        </ul>
        <SectionHeader title="Videos" />
        <Row>
          <Col xs={12} lg={6}>
            <YoutubeEmbed embedId="lFmqvpZfN1A" />
          </Col>
        </Row>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default BCMGuidesBeginnerPage;

export const Head: React.FC = () => (
  <Seo
    title="Beginner guide | Black Clover M | Prydwen Institute"
    description="The best beginner guide for Black Clover Mobile."
    game="bcm"
  />
);
